<app-circles></app-circles>

<h1 class="text-center mt-3 mb-3"> Resume</h1>

<div class="row">
  <div class="col-lg-12">
    <div class='card-deck'>

      <!-- Education -->
      <div class='card text-center'>
        <div class='card-body'>
          <div class='card-title'>
            <h2>Education</h2>
          </div>
          <table class="table  table-responsive text-center table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">School</th>
                <th scope="col"
                    style="white-space:nowrap;">Grad. Date</th>
                <th scope="col">GPA</th>
                <th scope="col">Degree</th>
              </tr>
            </thead>
            <tbody>

              <!-- Use Angular Structural Directive to Generate Table! -->
              <tr *ngFor="let school of schools; index as idx">
                <th scope="row">{{idx}}</th>
                <td>{{school.name }}</td>
                <td>{{school.date }}</td>
                <td>{{school.gpa }}</td>
                <td>{{school.degree }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Certifications -->
      <div class='card text-center'>
        <div class='card-body'>
          <div class='card-title'>
            <h2>Certifications</h2>
          </div>
          <table class="table  table-responsive table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Date </th>
                <th scope="col">Image</th>
              </tr>
            </thead>
            <tbody>

              <!-- Use Angular Structural Directive to Generate Table! -->
              <tr *ngFor="let cert of certifications; index as idx">
                <th scope="row">{{idx}}</th>
                <td>{{cert.name }}</td>
                <td>{{cert.date }}</td>
                <td>Coming Soon!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-lg-12">
    <div class="card-deck">

      <!-- Work Experience Carousel of Cards! -->
      <div class="card">
        <div class="card-body">

          <div class="card-title text-center">
            <h2>Work Experience</h2>
          </div>

          <div id="carouselExampleControls"
               class="carousel slide"
               data-ride="carousel">
            <div class="carousel-inner">

              <!-- Use Angular Structural Directive to Generate Job Cards! -->
              <div *ngFor="let job of workExperience; index as idx"
                   class="carousel-item"
                   [ngClass]="{'active' : idx === 0}"
                   active="true">
                <div class="card-title">
                  <h4>{{job.title}}</h4>
                </div>
                <div class="card-subtitle mb-2 text-muted">
                  {{job.company}} - {{job.location}} - {{job.date}}
                </div>
                <ul class="list-group list-group-flush">
                  <li *ngFor="let bullet of job.bullets"
                      class="list-group-item">{{bullet}}</li>
                </ul>
              </div>
            </div>

            <!-- Cycle Back One Card -->
            <a class="carousel-control-prev"
               href="#carouselExampleControls"
               role="button"
               data-slide="prev">
              <span class="carousel-control-prev-icon"></span>
            </a>

            <!-- Cycle Forward One Card -->
            <a class="carousel-control-next"
               href="#carouselExampleControls"
               role="button"
               data-slide="next">
              <span class="carousel-control-next-icon"></span>
            </a>
          </div>
        </div>
      </div>

      <!-- Technology Skills -->
      <div class="card">
        <div class="card-body text-center">
          <div class="card-title">
            <h2>Technology Skills</h2>
          </div>
          <div class="row">

            <!-- List of Software Languages -->
            <div class="col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="list-group-item"
                    style="white-space:nowrap;">
                  <b>Soft. Languages</b>
                </li>
                <li *ngFor="let language of skills.languages | slice:0:5"
                    class="list-group-item">{{language}}</li>
              </ul>
            </div>

            <!-- List of Web Development Skills -->
            <div class="col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="list-group-item"
                    style="white-space:nowrap;"><b>Web Development</b></li>
                <li *ngFor="let webDevSkill of skills.webDevelopment | slice:0:5"
                    class="list-group-item">{{webDevSkill}}</li>
              </ul>
            </div>

            <!-- List of Software Engineering Skills -->
            <div class="col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="list-group-item"
                    style="white-space:nowrap;"><b>Soft. Engineering</b></li>
                <li *ngFor="let softwareEngSkill of skills.softwareEngineering | slice:0:5"
                    class="list-group-item">{{softwareEngSkill}}</li>
              </ul>
            </div>
          </div>

          <!-- Open More-Skills Modal -->
          <button type="button"
                  class="btn btn-primary mt-3"
                  data-toggle="modal"
                  data-target="#exampleModal">
            More Skills!
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- More Skills Modal -->
<div class="modal fade"
     id="exampleModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header text-center">
        <h5 class="col-11 modal-title">More Software Skills!</h5>
        <button type="button"
                class="col-1 close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="modal-body">
        <div class="row">

          <!-- Divide Skills into Lists of 5 -->
          <div class="col-md-2"
               *ngFor="let ele of [5,10,15,20,25,30]">
            <ul class="list-group list-group-flush">
              <li *ngFor="let language of allSkills.sort() | slice:ele-5:ele"
                  class="list-group-item"
                  style="white-space:nowrap;">{{language}}</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Modal Footer -->
      <div class="modal-footer">
        <button type="button"
                class="btn btn-secondary"
                data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
