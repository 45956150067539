<div *ngFor="let msg of messages"
     class="alert alert-dismissible fade show alert-{{msg.type}}"
     role="alert">
  {{msg.msg}}
  <button type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
