<nav class="navbar navbar-expand-lg navbar-light bg-light">

  <!-- My name takes yuh home! -->
  <a class="navbar-brand"
     href="/">Ethan $ Tanen</a>

  <!-- NavBar dropdown for small screens -->
  <button class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-expanded="false">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- List of navigable items-->
  <div class="collapse navbar-collapse"
       id="navbarSupportedContent">

    <!-- Use structural directive to generate list of links -->
    <ul class="navbar-nav mr-auto">
      <li *ngFor="let link of links"
          class="nav-item"
          [routerLinkActive]="'active'">
        <a class="nav-link"
           routerLink={{link.path}}>
          {{link.name}}
        </a>
      </li>
    </ul>

  </div>
</nav>
