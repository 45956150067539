<app-circles></app-circles>

<!-- Center Card -->
<div class="row h-100" style="margin-top: 10%">
  <div class="col-md-6 offset-md-3">
    <div class="card text-center">
      <div class="card-body" style="margin-top: 10%">
        <!-- Title -->
        <div class="card-title">
          <h1 style="font-size: 2.5em">Ethan $ Tanen</h1>
          <h5>Developer, Musician, Outdoorsman</h5>
        </div>

        <!-- Lil Contact Info -->
        <div class="card-body" style="margin-top: 8%">
          <h5>
            Email:
            <a
              href="
             mailTo:
             ethantanen@yahoo.com"
              >ethantanen@yahoo.com</a
            >
          </h5>
          <h5>
            GitHub:
            <a href="https://github.com/ethantanen"
              >www.github.com/ethantanen</a
            >
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>
